const  appId = process.env.APPLICATION_ID;
const  locationId = process.env.LOCATION_ID;

async function initializeCard(payments) {
  const card = await payments.card();
  await card.attach('#card-container'); //PENDING: This is not happening upon go-back button!!
	return card;
}

async function tokenize(paymentMethod) {
	let amount = document.getElementById('coin_gain_total_amount').value;
	const verificationDetails = {
		amount: amount,
		/* collected from the buyer */
		billingContact: {
			// addressLines: ['123 Main Street', 'Apartment 1'],
			// familyName: 'Doe',
			// givenName: 'John',
			// email: 'jondoe@gmail.com',
			// countryCode: 'GB',
			// phone: '3214563987',
			// state: 'LND',
			// city: 'London',
		},
		currencyCode: 'JPY',
		intent: 'CHARGE',
    customerInitiated: true,
    sellerKeyedIn: false,
	};

	const tokenResult = await paymentMethod.tokenize(verificationDetails);
	if (tokenResult.status === 'OK') {
		return tokenResult.token;

	} else {
		let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
		if (tokenResult.errors) {
			errorMessage += ` and errors: ${JSON.stringify(
				tokenResult.errors
			)}`;
		}
		throw new Error(errorMessage);
		// return errorMessage
	}
}

document.addEventListener('turbolinks:load', async function () {
	if (!window.Square || !document.getElementById('card-container')) {
		return;
	}
	let payments;
	try {
		payments = window.Square.payments(appId, locationId);
	} catch(e) {
		const statusContainer = document.getElementById(
			'payment-status-container'
		);
		statusContainer.className = 'missing-credentials';
		statusContainer.style.visibility = 'visible';
		console.error(e);

		return;
	}

	let card;
	try {
    card = await initializeCard(payments);
    const cardholderNameInput = document.createElement('input');
    cardholderNameInput.setAttribute('type', 'text');
    cardholderNameInput.setAttribute('id', 'cardholder-name');
    cardholderNameInput.setAttribute('placeholder', 'Cardholder name (Optional)');
    cardholderNameInput.setAttribute('class', 'mb-1');
    cardholderNameInput.setAttribute('required', '');
    document.getElementById('card-container').insertBefore(cardholderNameInput, document.getElementById('card-container').firstChild);

  } catch (e) {
    console.error('Initializing Card failed', e);
    return;
  }

  async function handlePaymentMethodSubmission(
    event,
    paymentMethod
  ) {
    event.preventDefault();

    try {
      // disable the submit button as we await tokenization and make a payment
      // request.
      cardButton.value = 'Processing...';
      cardButton.disabled = true;
      const token = await tokenize(paymentMethod);

      // Add tokens to the form.
      document.getElementById('coin_gain_card-nonce').value = token;
      // Submit the page form.
      document.getElementById('payment-form').submit();

    } catch (e) {
      cardButton.value = oriButtonValue;
      cardButton.disabled = false;
      console.error(e.message);
    }
  }

  const cardButton = document.getElementById('card-button');
  const oriButtonValue = cardButton.value
  cardButton.addEventListener('click', async function (event) {
    await handlePaymentMethodSubmission(event, card);
  });
});



